<template>
	<div class="outside-box">
		<iframe
			id="myIframe"
			ref="myIframe"
			class="iframe-box"
			src="https://www.overseetracking.com/loginentry.aspx?username=BeesCredit&password=BeesCredit"
			frameborder="0"
		></iframe>
		<div></div>
	</div>
</template>
<script>
export default {}
</script>
<style scoped lang="less">
.outside-box {
	position: relative;
}

.iframe-box {
	position: absolute;
	width: 100%;
	height: 90vh;
}
</style>
